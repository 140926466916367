import './scss/main.scss'

import $ from 'jquery';

$('form[id="formContat"]').submit(function(){
	$('.message').removeClass("success error")

	var nome = $('#nome').val();
	var email = $('#email').val();
	var telefone = $('#telefone').val();
	var mensagem = $('#mensagem').val();
	
    var data = {
        email: email, 
        nome: nome,
        telefone: telefone,
        mensagem: mensagem
    }
    $('form[id="formContat"] button').attr("disabled", true);
    $.ajax({
        type: "POST",
        url: "https://equestreatp.com.br/contato.php",
        data: data,
        beforeSend : function(){
            $(".message").html("Enviando..")
        }
    }).done(function(resultado){
        if(resultado==1){
            $('.message').addClass("success")
            $('form[id="formContat"]').trigger("reset");
            $(".message").html("Sua mensagem foi enviada com sucesso, em breve entraremos em contato.")
        }else{
            $(".message").html("Ocorreu um erro ao tentar enviar sua mensagem. Por favor tente novamente mais tarde!")
        }
        $('form[id="formContat"] button').attr("disabled", false);
    });
	return false;
});

$(".button-menu").click(function(){
	$(".menu-mobile").addClass("active");
});

$(".button-menu-close").click(function(){
	$(".menu-mobile").removeClass("active");
});

$(".menu-mobile li a").click(function(){
	$(".menu-mobile").removeClass("active");
});

$(document).on('click', 'a.row-scroll', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 1000);
});